var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { MerchantKioskType } from "@common/models/kiosk";
import FilterModal from "@/components/modal/FilterModal.vue";
import { newQuery } from "@/models/Report";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.query = newQuery();
        this.tags = [];
        this.data = null;
    }
    get title() {
        if (!this.data)
            return "";
        return (this.data.Sharer.AccountName || "未命名") + "（" + this.data.Sharer.Username + "）";
    }
    beforeMount() {
        post('/api/tag').then(v => this.tags = v);
        this.load();
    }
    get filtered() {
        if (!this.data)
            return [];
        let result = this.data.Kiosks;
        if (this.query.mode == "id" && this.query.id.length) {
            const ids = new Set(this.query.id);
            result = result.filter(d => ids.has(d.Id));
        }
        else if (this.query.mode == "tag") {
            const tag = new Set(this.query.tag);
            const exTag = new Set(this.query.exTag);
            result = result.filter(d => (tag.size == 0 || d.Tags.some(t => tag.has(t.Id))) &&
                !d.Tags.some(t => exTag.has(t.Id)));
        }
        return result;
    }
    get MerchantKioskType() {
        return MerchantKioskType;
    }
    get disabled() {
        return this.data != null && !this.data.Sharer.Added && this.all(null);
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.data = yield post("/api/Share/Detail", { Id: this.id });
            }
            catch (e) {
                this.data = null;
                throw e;
            }
        });
    }
    showFilter() {
        this.$refs.modal.show();
    }
    setAll(type) {
        if (!this.data)
            return;
        for (let k of this.filtered)
            k.MerchantKioskType = type;
    }
    all(type) {
        if (!this.data)
            return false;
        return this.filtered.every(k => k.MerchantKioskType == type);
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            const nothing = this.all(null);
            if (this.data.Sharer.Added && nothing && !(yield this.confirm("確定要移除這個共享人？")))
                return;
            yield post("/api/Share/Set", Object.assign({}, this.data));
            if (nothing)
                this.$router.push("/share");
            else
                this.$router.back();
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { FilterModal } })
], Page);
export default Page;
