var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop } from "vue-property-decorator";
import Modal from "@/components/modal";
export function filter(data, keyword) {
    if (!data)
        return [];
    if (!keyword)
        return data;
    const reg = new RegExp(keyword, "i");
    return data.filter(s => reg.test(s.AccountName) || reg.test(s.Username));
}
let DailyModal = class DailyModal extends Modal {
    constructor() {
        super(...arguments);
        this.keyword = "";
        this.error = "";
    }
    ok() {
        if (filter(this.data, this.keyword).length) {
            this.value = this.keyword;
            this.close();
        }
        else {
            this.error = "* 查無此共享人帳號";
        }
    }
};
__decorate([
    Prop()
], DailyModal.prototype, "data", void 0);
DailyModal = __decorate([
    Component
], DailyModal);
export default DailyModal;
